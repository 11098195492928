import React, { useState } from 'react';
import { site_ip } from '../globalSetting';
import axios from 'axios';
import { Col, Row, Form } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate=useNavigate();
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    axios({
      method: 'post',
      url: site_ip + '/auth/signin',
      data: {
        user_email: email,
        password: password,
      },
      dataType: 'json',
    })
      .then(function (response) {
        if (response?.data.Status === 200) {
          localStorage.setItem('user_id', response?.data.Content.user_id);
          localStorage.setItem('user',JSON.stringify(response?.data.Content));
          localStorage.setItem('user_group', JSON.stringify(response?.data.UserGroups));
          navigate('/dashboard');
        } else {
          alert('Email or Password is incorrect!');
        }
      })
      .catch(function (error) {
        console.log('error1' + error);
      });
  };

  return (
    
      <div>
        <Row style={{ marginTop: '10px',marginBottom: '10px'}}>
          <Col md={4}></Col>
          <Col md={4} className="align-items-center justify-content-center">
            <div className="login-box-div">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <p className='registerLabel'>Username or E-mail</p>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="user_email"
                    value={email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <p className='registerLabel'>Password</p>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={handleChangePassword}
                  />
                </Form.Group>
                <div style={{textAlign: 'center',marginBottom:'20px'}}>
                  <button className='primary-btn' onClick={()=>handleLogin()}>LOGIN</button>
                </div>
                <div style={{textAlign: 'center'}}>
                  <a className='primary-btn' href='#'>Forgot your password?	</a>
                </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </div>
  );
}

export default Login;
