import { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "./diabetesgroup.css";
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import Select from 'react-select'
const JoinNewGroup = (props) => {
    
    const [groupId, setGroupId] = useState([]);
    const [interestGroups, setInterestGroups] = useState([]);
    
    const [error, setError] = useState("");
    const handleClose = () => {
        props.handleClose(2);
    };

    const [userGroup, setUserGroup] = useState([]);
    useEffect(() => {
        getUserGroupData();
    }, [props.existingGroups]);
    const getUserGroupData = () => {
        axios
            .get(site_ip + '/getUserGroups')
            .then((res) => {
                let filtered_group = [];
                filtered_group = res.data.Content?.filter((item) => {
                    if(item?.ug_id !== props.existingGroups[0]?.ug_id){
                        return item
                    }
                })
                setUserGroup(filtered_group);
            })
            .catch((err) => console.log(err));
    };

    
    const handleUserGroup = (user_id) => {
        let req = [];
        if(interestGroups?.length === 0){
            alert("Please select a group.")
            return 
        }
        interestGroups?.map((item)=>{
            let ele = {
                ug_id: item?.ug_id,
                whatsapp_group_join_ind: "N",
                user_id: localStorage.getItem("user_id")
            };
            req.push(ele)
        })

        axios
            .post(site_ip + '/auth/addUserRegistrationGroups', req)
            .then((res) => {
                setError('');
                props.handleClose(1);
            })
            .catch((err) => console.log(err));
    }

    return (
        <Modal
            onHide={handleClose}
            show={props.textPopup}
            size={"md"}
            // dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Join Second Group
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <div style={{ height: "auto" }}>
                        {error && (
                            <div className="form-group">
                                <div
                                    className="alert alert-danger alert-dismissible"
                                    role="alert"
                                >
                                    {error}
                                </div>
                            </div>
                        )}
                    </div>
                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>Interest Group<span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Select
                                getOptionLabel={(option) => option.ug_name}
                                getOptionValue={(option) => option.ug_id}
                                labelKey={"ug_name"}
                                valueKey={"ug_id"}
                                optionLabel={"Zone"}
                                value={interestGroups}
                                options={userGroup}
                                isOptionDisabled={() => interestGroups.length >= 1}
                                onChange={(selected) => {
                                    setInterestGroups(selected)
                                }}
                                isMulti
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer
                className="card-footer"
            >
                <Button
                    className="submit"
                    type="button"
                    style={{ width: "120px" }}
                    onClick={() => handleUserGroup()}
                >
                    Add Group
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default JoinNewGroup;
