let menu_items;
menu_items = {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'menu4',
          title: 'Manage Public Images',
          children: [
            {
              id: 'submenu1',
              title: 'Search and Edit',
              url: '/admin/images',
            },
            {
              id: 'submenu2',
              title: 'Add Public Image',
              url: '/admin/add-image',
            },
          ],
        },
        {
          id: 'menu4',
          title: 'Manage Public Videos',
          children: [
            {
              id: 'submenu1',
              title: 'Search and Edit',
              url: '/admin/videos',
            },
            {
              id: 'submenu2',
              title: 'Add Public Video',
              url: '/admin/add-video',
            },
          ],
        },
        {
          id: 'profile',
          title: 'Profile',
          children: [
            {
              id: 'logout',
              title: 'Logout',
              url: '/admin/logout',
            },
          ],
        },
      ],
    },
  ],
};

export default menu_items;
