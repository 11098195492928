import { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "./diabetesgroup.css";
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import { useNavigate } from "react-router-dom";
const DiabetesPopup = (props) => {
    const navigate = useNavigate();
    const initialState = {
        user_id: '',
        user_email: "",
        password: "",
        salutation: "",
        salutation_name: "",
        first_name: "",
        last_name: "",
        user_mobile: "",
        city: "",
        state: "",
        country_id: "",
        mci_number: "",
        zipcode: "",
        password2: "",
        address:""
    };
    const [groupId, setGroupId] = useState([]);
    const [siteUserData, setSiteUserData] = useState(localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : initialState);
    const [interestGroups, setInterestGroups] = useState([]);
    const handleChange = (e) => {
        setError('');
        const { name, value } = e.target;
        setSiteUserData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    }
    const [userGroup, setUserGroup] = useState([]);
    useEffect(() => {
        getUserGroupData();
        getCountries();
    }, []);
    const [countries, setCountries] = useState([])
    const getCountries = () => {
        axios
            .get(site_ip + '/getCountries')
            .then((res) => {
                setCountries(res.data.Data);
            })
            .catch((err) => console.log(err));
    };

    const getUserGroupData = () => {
        axios
            .get(site_ip + '/getUserGroups')
            .then((res) => {
                setUserGroup(res.data.Content);
            })
            .catch((err) => console.log(err));
    };

    const [error, setError] = useState("");
    const handleClose = () => {
        props.onClose(2);
    };

    const validateEmail = (mail) => {
        if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                mail
            )
        ) {
            return true;
        }
        return false;
    };
    const validateMobile = (mobile) => {
        if (/^(\+\d{1,3}[- ]?)?\d{10}$/.test(mobile)) {
            return true;
        }
        return false;
    }
    const handleSubmit = async () => {
        if (siteUserData?.salutation === "") {
            setError("Please select salutation");
        }else if (siteUserData?.salutation === "Others" && siteUserData?.salutation_name === "") {
            setError("Please specify salutation");
        } else if (siteUserData?.first_name === "") {
            setError("Please enter first name");
        } else if (siteUserData?.last_name === "") {
            setError("Please enter last name");
        } else if (!validateEmail(siteUserData?.user_email)) {
            setError("Please enter valid email");
        } else if (!validateMobile(siteUserData?.user_mobile)) {
            setError("Please enter valid mobile number");
        }else if (siteUserData?.salutation === "Dr" && siteUserData?.mci_number === "") {
            setError("Please add MCI number");
        } 
        else if (siteUserData?.city === "") {
            setError("Please add city name");
        } else if (siteUserData?.state === "") {
            setError("Please add state name");
        } 
        else if (siteUserData?.country_id === "") {
            setError("Please select country");
        }else if (siteUserData?.zipcode === "") {
            setError("Please enter zip code");
        }else if (siteUserData?.address?.trim() === "") {
            setError("Please add your address.");
        } 
        // else if (siteUserData?.password.length < 8) {
        //     setError("Password must be up to 8 characters");
        // } 
        // else if (siteUserData?.password !== siteUserData?.password2) {
        //     setError("Passwords do not match");
        // } 
        else {
            const userData = {
                user_id: siteUserData?.user_id,
                salutation: siteUserData?.salutation,
                first_name: siteUserData?.first_name,
                last_name: siteUserData?.last_name,
                user_email: siteUserData?.user_email,
                gender: siteUserData?.gender,
                user_mobile: siteUserData?.user_mobile,
                city: siteUserData?.city,
                state: siteUserData?.state,
                country_id: siteUserData?.country_id,
                mci_number: siteUserData?.mci_number,
                zipcode: siteUserData?.zipcode,
                address:siteUserData?.address
                // password: siteUserData?.password,
            };
            axios
                .post(site_ip + (siteUserData?.user_id === '' ? '/auth/signup' : '/auth/setUser'), userData)
                .then((res) => {
                    if(res.data.Status === 200){
                        localStorage.setItem('user',JSON.stringify(userData));
                        props?.onClose(1)
                    }else{
                        alert(res.data?.message);
                    }
                })
                .catch((err) => console.log(err));
        }
    };
    
    console.log(siteUserData)

    return (
        <Modal
            onHide={handleClose}
            show={props.visible}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Update Profile
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <div style={{ height: "auto" }}>
                        {error && (
                            <div className="form-group">
                                <div
                                    className="alert alert-danger alert-dismissible"
                                    role="alert"
                                >
                                    {error}
                                </div>
                            </div>
                        )}
                    </div>
                    <p className="form-section">Personal Details</p>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>Salutation <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Form.Control
                                as="select"
                                class="form-control"
                                name="salutation"
                                value={siteUserData?.salutation}
                                onChange={(e) => handleChange(e)}
                            >
                                <option value="">-Select Salutation-</option>
                                <option value="Dr">Dr</option>
                                <option value="Others">Others</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    {siteUserData?.salutation === "Others" ? 
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>Specify Salutation<span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name="salutation_name"
                                value={siteUserData?.salutation_name}
                                onChange={(e) => handleChange(e)}
                            />
                        </Form.Group>
                    </Col> : null }
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>First Name <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name="first_name"
                                value={siteUserData?.first_name}
                                onChange={(e) => handleChange(e)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>Last Name <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name="last_name"
                                value={siteUserData?.last_name}
                                onChange={(e) => handleChange(e)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>E-mail / Username<span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Form.Control
                                type="email"
                                style={{backgroundColor:"#ccc"}}
                                readOnly
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name="user_email"
                                value={siteUserData?.user_email}
                                onChange={(e) => handleChange(e)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>Mobile Number <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name="user_mobile"
                                value={siteUserData?.user_mobile}
                                onChange={(e) => handleChange(e)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>MCI Number {siteUserData?.salutation === "Dr" ? <span style={{ color: "#ff0000" }}>*</span> : null } </Form.Label>
                            <Form.Control
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name="mci_number"
                                value={siteUserData?.mci_number}
                                onChange={(e) => handleChange(e)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <p className="form-section">Other Details</p>
                <Row>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>Country Name <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Form.Control
                                as="select"
                                class="form-control"
                                name="country_id"
                                value={siteUserData?.country_id}
                                onChange={(e) => handleChange(e)}
                            >
                                <option value="">-Select Country-</option>
                                {countries?.map((item) => {
                                    return (
                                        <option value={item?.id} key={item?.id}>{item?.country_name}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>State Name <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                class="form-control"
                                id="exampleFormControlSelect1"
                                name="state"
                                value={siteUserData?.state}
                                onChange={(e) => handleChange(e)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>City Name <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                class="form-control"
                                id="exampleFormControlSelect1"
                                name="city"
                                value={siteUserData?.city}
                                onChange={(e) => handleChange(e)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>Pin Code <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name="zipcode"
                                value={siteUserData?.zipcode}
                                onChange={(e) => handleChange(e)}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>Address<span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                row="2"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name="address"
                                value={siteUserData?.address}
                                onChange={(e) => handleChange(e)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer
                className="card-footer"
            >
                <Button
                    className="submit"
                    type="button"
                    style={{ width: "120px" }}
                    onClick={() => handleSubmit()}
                >
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default DiabetesPopup;
