import { useState, useEffect, useRef } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import html2canvas from 'html2canvas';
const AddDocters = (props) => {
    const navigate = useNavigate();
    const ref = useRef();
    const initialState = {
        doctor_name: "",
        clinic_name: "",
        doctor_mobile: "",
        clinic_city: "",
    };
    const [data, setData] = useState(initialState);
    const [display, setDisplay] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    }
    const handleSubmit = () => {


        const docterData = {
            doctor_name: data?.doctor_name,
            clinic_name: data?.clinic_name,
            doctor_mobile: data?.doctor_mobile,
            clinic_city: data?.clinic_city,
            language_id: props.languageId,
            content_id: props.contentId
        };
        axios
            .post(site_ip + '/addDoctorDownload', docterData)
            .then((res) => {
                if (res.data.Status === 200) {
                    props.handleClose();
                    setDisplay(true);
                } else {
                    alert(res.data?.message);
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <>
            {display ? <ImageWithText
                imageSrc={props.contentImage}
                name={data.doctor_name}
                address={data.clinic_name + ',' + data.clinic_city}
                mobile={data.doctor_mobile}
                x={255}
            /> : null}

            <Modal
                onHide={props.handleClose}
                show={props.textPopup}
                dialogClassName="modal-50w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Please Submit details to download files !
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ fontSize: "11px" }}>Docter Name </Form.Label>
                                <Form.Control
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="doctor_name"
                                    value={data?.doctor_name}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ fontSize: "11px" }}>Clinic Name </Form.Label>
                                <Form.Control
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="clinic_name"
                                    value={data?.clinic_name}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ fontSize: "11px" }}>Doctor Mobile Number </Form.Label>
                                <Form.Control
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="doctor_mobile"
                                    value={data?.doctor_mobile}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ fontSize: "11px" }}>Clinic City </Form.Label>
                                <Form.Control
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="clinic_city"
                                    value={data?.clinic_city}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer
                    className="card-footer"
                >
                    <Button
                        className="submit"
                        // type="button"
                        style={{ width: "200px" }}
                        onClick={() => handleSubmit()}
                    >
                        Submit & Download
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};
export default AddDocters;

const ImageWithText = ({ imageSrc, name, address, mobile, x }) => {
    const canvasRef = useRef(null);
    const [show,setShow]=useState(true);
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const image = new Image();
        // image.crossOrigin = "Anonymous";
        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            ctx.font = "10px Arial";
            ctx.fillText(name, x, '83');
            ctx.fillText(address, x, '100');
            ctx.fillText(mobile, x, '118');
        };
        image.crossOrigin = 'Anonymous';
        // image.setAttribute('crossorigin', 'anonymous');
        image.src = imageSrc;
    }, [imageSrc, name, address, mobile, x]);
    const downloadImage = () => {
        const canvas = canvasRef.current;
        const dataURL = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');
        link.download = 'image.jpg';
        link.href = dataURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <Modal
            onHide={()=>setShow(false)}
            show={show}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton></Modal.Header>
            <canvas id="canvas" ref={canvasRef} width={800} height={800} />
            <button onClick={() => downloadImage()}>Download Image</button>
        </Modal>
    );
}

