import React, { useState, useEffect } from 'react';
import { Container, Button, Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import Aux from '../../hoc/_Aux';
import { site_ip } from '../../globalSetting';
import { fileUpload } from '../fileUploads';
import { useNavigate } from 'react-router-dom';
export default function AddPublicImage(props) {
  const navigate = useNavigate();
  const initialState = {
    video_title: "",
    video_image:"",
    author_name: "",
    content_type_flag: "",
    download_cnt: "",
    language_id: '',
    subject_id: '',
    publish_date: ''
  };
  const [data, setData] = useState(initialState);
  const [contentImage, setContentImage] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [language, setLanguage] = useState([]);
  useEffect(() => {
    getLanguageList();
    getSubjectList();
    // eslint-disable-next-line
  }, []);
  const getLanguageList = () => {
    // Send a Get request
    axios
      .get(site_ip + "/getLanguages")
      .then((response) => {
        setLanguage(response.data.Content);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const [subjects, setSubjects] = useState([]);
  const getSubjectList = () => {
    // Send a Get request
    axios
      .get(site_ip + "/getSubjects")
      .then((response) => {
        setSubjects(response.data.content);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const handleSubmit = async() => {
    let req = {...data};
    if(data?.video_title?.trim() === ""){
      alert("Video title cannot be blank.")
      return
    }else if(data?.author_name?.trim() === ""){
      alert("Author name cannot be blank.")
      return
    }else if(data?.language_id === ""){
      alert("Please select language.")
      return
    }else if(data?.subject_id === ""){
      alert("Please select subject.")
      return
    }else if(contentImage === null){
      alert("Please add content image.")
      return
    }

    if (contentImage !== null) {
      let fileUrl = await fileUpload(contentImage[0], 'video')
      req = { ...req, video_image: fileUrl }
    }

    if (thumbnail !== null) {
      let fileUrl = await fileUpload(thumbnail[0], 'video')
      req = { ...req, thumbnail: fileUrl }
    }

    axios
      .post(site_ip + '/addAwarenessVideo', req)
      .then((res) => {
        if (res.data.Status === 200) {
          // setData(initialState);
          alert("Content video successfully added.")
          navigate("/admin/videos")
        } else {
          alert(res.data?.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Aux>
      <Container>
        <br />
        <h4>Add Public Video</h4>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "14px" }}>Video Name <span style={{color:"#ff0000"}}>*</span></Form.Label>
              <Form.Control
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="video_title"
                value={data?.video_title}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "14px" }}>Author Name <span style={{color:"#ff0000"}}>*</span></Form.Label>
              <Form.Control
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="author_name"
                value={data?.author_name}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "14px" }}>Language <span style={{color:"#ff0000"}}>*</span></Form.Label>
              <Form.Control
                as="select"
                class="form-control"
                id="exampleInputEmail5"
                aria-describedby="emailHelp"
                name="language_id"
                value={data?.language_id}
                onChange={(e) => handleChange(e)}
              >
                <option value=''>Select Language</option>
                {language.map((data, index) => {
                  return (
                    <option value={data.language_id}>{data.language_name}</option>
                  )
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "14px" }}>Subject <span style={{color:"#ff0000"}}>*</span></Form.Label>
              <Form.Control
                as="select"
                class="form-control"
                id="exampleInputEmail5"
                aria-describedby="emailHelp"
                name="subject_id"
                value={data?.subject_id}
                onChange={(e) => handleChange(e)}
              >
                <option value=''>Select Subject</option>
                {subjects?.map((option) => (
                  <option value={option.subject_id}>
                    {option.subject_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "14px" }}>Content Type Flag <span style={{color:"#ff0000"}}>*</span></Form.Label>
              <Form.Control
                as="select"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="content_type_flag"
                value={data?.content_type_flag}
                onChange={(e) => handleChange(e)}
              >
                <option>Select Content Type Flag</option>
                <option value='Image'>Image</option>
                <option value='HTML'>HTML</option>
                <option value='PDF'>PDF</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "14px" }}>Download Count</Form.Label>
              <Form.Control
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="download_cnt"
                value={data?.download_cnt}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "14px" }}>Video <span style={{color:"#ff0000"}}>*</span></Form.Label>
              <Form.Control
                  type="file"
                  // accept="image/png, image/jpg, image/jpeg"
                  name="cover_image1"
                  onChange={(e) => setContentImage(e.target.files)}
              />
              {/* {data?.video_image !== null || data?.video_image !== "" ?
              <img src={data?.video_image} style={{height:'50px', width:"50px"}} /> : null} */}
            </Form.Group>
          </Col>
          {/* <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "14px" }}>Thumbnail</Form.Label>
              <Form.Control
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  name="cover_image1"
                  onChange={(e) => setThumbnail(e.target.files)}
              />
            </Form.Group>
          </Col> */}
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "14px" }}>Publish Date</Form.Label>
              <Form.Control
                type="date"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="publish_date"
                value={data?.publish_date}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </Col>
          <Col md={6} className='mt-4'>
            <Button
              className="submit"
              // type="button"
              style={{ width: "200px" }}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Container>
    </Aux>
  );
}
