import React, { useEffect, useState } from "react";
import "./Header.css";
import UpdateProfile from "../components/DiabetesGroup/UpdateProfile";
import { useNavigate, useLocation } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [smallMenuOpen, setSmallMenuOpen] = useState(false)
  const [subMenus, setSubMenus] = useState([false, false, false, false, false])
  const [editProfilePopup, setEditProfilePopup] = useState(false)
  const user = localStorage.getItem("user");
  const handleSubMenu = (index) =>{
    let sub_menus = [...subMenus];
    sub_menus[index] = !sub_menus[index];
    setSubMenus(sub_menus)
  }

  useEffect(()=>{
    const user = localStorage.getItem("user");
    if(user !== null){
      navigate("/dashboard")
    }
  }, [])

  const handlePopupClose = (type, val) => {
    if(type === "profile"){
      setEditProfilePopup(false)
      if(val === 1){
        alert("Profile successfully updated!")
        window.location.reload();
      }
    }
  }

  const logmeOut = () =>{
    localStorage.clear();
    navigate("/")
  }

  return (
    <>
      <header className="wraper_header style-twelve floating-header">
        {/* <div className="wraper_header_top d-none d-lg-block d-md-block">
          <div className="container">
            <div className="row header_top">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                <div className="header_top_item">
                  <ul className="contact">
                    <li className="phone">Call Us: +91 79 26304104 / 8104</li>
                    <li className="email">Email Us:  Diabetesindia2021@gmail.com</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                <div className="header_top_item">
                  <div className="header-data-social hidden-xs">
                    <ul className="social">
                      <li className="facebook"><a href="https://www.facebook.com/DiabetesIndiaTrust" target="&quot;_blank&quot;"><i className="fa fa-facebook"></i></a></li>
                      <li className="twitter"><a href="https://twitter.com/diabetesindia22" target="&quot;_blank&quot;"><i className="fa fa-twitter"></i></a></li>
                      <li className="linkedin"><a href="https://www.linkedin.com/company/diabetes-india/" target="&quot;_blank&quot;"><i className="fa fa-linkedin"></i></a></li>
                      <li className="instagram"><a href="https://www.instagram.com/diabetesindia2022/" target="&quot;_blank&quot;"><i className="fa fa-instagram"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div id="sticky-wrapper" className="sticky-wrapper"><div className="wraper_header_main i-am-sticky">
          <div className="container-fluid">
            <div className="header_main">
              <div className="brand-logo">
                <a href="https://www.diabetesindia.org.in/"><img src="https://www.diabetesindia.org.in/wp-content/uploads/2022/05/diabetesindia-logo.png" alt="" data-no-retina="" /></a>
              </div>
              <div className="header_main_action">
                <ul>
                </ul>
              </div>
              <div className="responsive-nav d-block d-md-none">
                <i className="fa fa-bars" onClick={() => setSmallMenuOpen(!smallMenuOpen)}></i>
              </div>
              <nav className="nav d-none d-lg-block d-md-block">
                <div className="menu-top-main-menu-container"><ul id="menu-top-main-menu" className="menu">
                  <li id="menu-item-9606" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-9606"><a href="https://www.diabetesindia.org.in/">Home</a></li>
                  {user !== null && location?.pathname === "/dashboard" ? <li id="menu-item-9606" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-9606"><a href="/" onClick={(e) => {e.preventDefault(); setEditProfilePopup(true)}}>Edit Profile</a></li> : null }
                  {user !== null && location?.pathname === "/dashboard" ? <li id="menu-item-9606" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-9606"><a href="/" onClick={(e) => {e.preventDefault(); logmeOut()}}>Logout</a></li> : null }
                  {/* <li id="menu-item-8140" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8140"><a href="#">About Diabetes India</a>
                    <ul className="sub-menu">
                      <li id="menu-item-8138" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8138"><a href="https://www.diabetesindia.org.in/about-diabetes-india/">About Diabetes India</a></li>
                      <li id="menu-item-8137" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8137"><a href="https://www.diabetesindia.org.in/dr-shaukat-sadikot/">Dr Shaukat Sadikot</a></li>
                      <li id="menu-item-8135" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8135"><a href="https://www.diabetesindia.org.in/dgenius-group/">DGENius Group</a></li>
                      <li id="menu-item-8139" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8139"><a href="#">Awards</a>
                        <ul className="sub-menu">
                          <li id="menu-item-8134" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8134"><a href="https://www.diabetesindia.org.in/diabetes-india-usv-awards/">Diabetes India -USV Awards</a></li>
                          <li id="menu-item-8133" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8133"><a href="https://www.diabetesindia.org.in/dr-shaukat-sadikot-oration/">Dr Shaukat Sadikot Oration</a></li>
                          <li id="menu-item-8132" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8132"><a href="https://www.diabetesindia.org.in/dr-siddharth-n-shah-memorial-lecture/">Dr Siddharth N Shah Memorial Lecture</a></li>
                          <li id="menu-item-8131" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8131"><a href="https://www.diabetesindia.org.in/fellowship-of-diabetesindia/">Fellowship Of DiabetesIndia</a></li>
                          <li id="menu-item-8130" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8130"><a href="https://www.diabetesindia.org.in/lilly-diabetesindia-lecture/">Lilly-DiabetesIndia Lecture</a></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li id="menu-item-9715" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9715"><a href="https://www.diabetesindia.org.in/diabetes-interest-groups/">Diabetes Interest Groups</a></li>
                  <li id="menu-item-8167" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8167"><a href="https://www.diabetesindia.org.in/text-books/">Text Books</a></li>
                  <li id="menu-item-8165" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8165"><a href="https://www.diabetesindia.org.in/journal/">Journal</a></li>
                  <li id="menu-item-9410" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9410"><a href="https://www.diabetesindia.org.in/type-1-network/">Type 1 Network</a></li>
                  <li id="menu-item-8168" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8168"><a href="#">Knowledge Network</a>
                    <ul className="sub-menu">
                      <li id="menu-item-8176" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8176"><a href="https://www.diabetesindia.org.in/editorial-board-diabetes-knowledge-network/">Editorial Board – Diabetes Knowledge Network</a></li>
                      <li id="menu-item-8880" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8880"><a href="http://diabetesindia2021.com/podcast/">Podcast</a></li>
                      <li id="menu-item-9560" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9560"><a target="_blank" rel="noopener" href="https://www.diabetesindia.org.in/wp-content/uploads/2023/01/Oral%20Health%20Special%20Interest%20Group-1.pdf">Oral Health Special Interest Group</a></li>
                      <li id="menu-item-8172" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8172"><a href="https://www.diabetesindia.org.in/archives/">Archives</a></li>
                      <li id="menu-item-8171" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8171"><a href="https://www.diabetesindia.org.in/global-standards-of-care/">Global Standards Of Care</a></li>
                      <li id="menu-item-9353" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9353"><a href="#">Study Group</a></li>
                    </ul>
                  </li>
                  <li id="menu-item-8177" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8177"><a href="#">International Alliance</a>
                    <ul className="sub-menu">
                      <li id="menu-item-8208" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8208"><a href="https://www.diabetesindia.org.in/idf/">IDF</a></li>
                      <li id="menu-item-8207" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8207"><a href="https://www.diabetesindia.org.in/ada/">ADA</a></li>
                      <li id="menu-item-8206" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8206"><a href="https://www.diabetesindia.org.in/desg/">DESG</a></li>
                      <li id="menu-item-8205" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8205"><a href="https://www.diabetesindia.org.in/easd/">EASD</a></li>
                      <li id="menu-item-8204" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8204"><a href="https://www.diabetesindia.org.in/pcde/">PCDE</a></li>
                      <li id="menu-item-8203" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8203"><a href="https://www.diabetesindia.org.in/codhy/">CODHY</a></li>
                      <li id="menu-item-8202" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8202"><a href="https://www.diabetesindia.org.in/ispad/">ISPAD</a></li>
                      <li id="menu-item-8201" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8201"><a href="https://www.diabetesindia.org.in/dasg/">DASG</a></li>
                      <li id="menu-item-8200" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8200"><a href="https://www.diabetesindia.org.in/attd/">ATTD</a></li>
                      <li id="menu-item-8199" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8199"><a href="https://www.diabetesindia.org.in/ihsg/">IHSG</a></li>
                    </ul>
                  </li>
                  <li id="menu-item-8254" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8254"><a target="_blank" rel="noopener" href="https://www.diabetesindia2023.com/">Conference</a></li>
                  <li id="menu-item-9713" className="login-logout menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-9576 current_page_item menu-item-9713"><a href="https://www.diabetesindia.org.in/login/" aria-current="page">Login</a></li> */}
                </ul>
                </div>
              </nav>
              <div className="clearfix"></div>
            </div>
          </div>
        </div></div>
      </header>
      {smallMenuOpen ? 
      <div id="mobile-menu" className="sidr right" aria-hidden="false" ><div className="sidr-inner">
        <div className="mobile-menu-main">
          <div className="mobile-menu-close">
            <i className="fa fa-times" onClick={() => setSmallMenuOpen(false)}></i>
          </div>
          <nav className="mobile-menu-nav">
            <div className="menu-top-main-menu-container"><ul id="menu-top-main-menu-1" className="menu">
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-3765 current_page_item menu-item-9606" style={{minWidth:"200px"}}><a href="https://www.diabetesindia.org.in/" aria-current="page">Home</a></li>
              {user !== null && location?.pathname === "/dashboard" ? <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-3765 current_page_item menu-item-9606" style={{minWidth:"200px"}}><a href="/" onClick={(e) => {e.preventDefault(); setEditProfilePopup(true)}} aria-current="page">Edit Profile</a></li> : null }
              {user !== null && location?.pathname === "/dashboard" ? <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-3765 current_page_item menu-item-9606" style={{minWidth:"200px"}}><a href="/" onClick={(e) => {e.preventDefault(); logmeOut(true)}} aria-current="page">Logout</a></li> : null } 
              {/* <li className={subMenus[0] ? "menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8168 radiantthemes-menu-open" : "menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8168"}><a href="/" onClick={(e) => {e.preventDefault(); handleSubMenu(0)}}>About Diabetes India</a>
                <ul className="sub-menu" style={{display: subMenus[0] ? "block" : "none"}}>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8138"><a href="https://www.diabetesindia.org.in/about-diabetes-india/">About Diabetes India</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8137"><a href="https://www.diabetesindia.org.in/dr-shaukat-sadikot/">Dr Shaukat Sadikot</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8135"><a href="https://www.diabetesindia.org.in/dgenius-group/">DGENius Group</a></li>
                  <li className={subMenus[3] ? "menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8139 radiantthemes-menu-open" : "menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8139"}><a href="/" onClick={(e) => {e.preventDefault(); handleSubMenu(3)}}>Awards</a>
                    <ul className="sub-menu" style={{display: subMenus[3] ? "block" : "none"}}>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8134"><a href="https://www.diabetesindia.org.in/diabetes-india-usv-awards/">Diabetes India -USV Awards</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8133"><a href="https://www.diabetesindia.org.in/dr-shaukat-sadikot-oration/">Dr Shaukat Sadikot Oration</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8132"><a href="https://www.diabetesindia.org.in/dr-siddharth-n-shah-memorial-lecture/">Dr Siddharth N Shah Memorial Lecture</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8131"><a href="https://www.diabetesindia.org.in/fellowship-of-diabetesindia/">Fellowship Of DiabetesIndia</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8130"><a href="https://www.diabetesindia.org.in/lilly-diabetesindia-lecture/">Lilly-DiabetesIndia Lecture</a></li>
                    </ul>
                    <span className="radiantthemes-open-submenu"></span></li>
                </ul>
                <span className="radiantthemes-open-submenu"></span></li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9715"><a href="https://www.diabetesindia.org.in/diabetes-interest-groups/">Diabetes Interest Groups</a></li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8167"><a href="https://www.diabetesindia.org.in/text-books/">Text Books</a></li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8165"><a href="https://www.diabetesindia.org.in/journal/">Journal</a></li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9410"><a href="https://www.diabetesindia.org.in/type-1-network/">Type 1 Network</a></li>
              <li className={subMenus[1] ? "menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8168 radiantthemes-menu-open" : "menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8168"}><a href="/" onClick={(e) => {e.preventDefault(); handleSubMenu(1)}}>Knowledge Network</a>
                <ul className="sub-menu" style={{display: subMenus[1] ? "block" : "none"}}>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8176"><a href="https://www.diabetesindia.org.in/editorial-board-diabetes-knowledge-network/">Editorial Board – Diabetes Knowledge Network</a></li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8880"><a href="http://diabetesindia2021.com/podcast/">Podcast</a></li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9560"><a target="_blank" rel="noopener" href="https://www.diabetesindia.org.in/wp-content/uploads/2023/01/Oral%20Health%20Special%20Interest%20Group-1.pdf">Oral Health Special Interest Group</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8172"><a href="https://www.diabetesindia.org.in/archives/">Archives</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8171"><a href="https://www.diabetesindia.org.in/global-standards-of-care/">Global Standards Of Care</a></li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9353"><a href="#">Study Group</a></li>
                </ul>
                <span className="radiantthemes-open-submenu"></span></li>
              <li className={subMenus[2] ? "menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8168 radiantthemes-menu-open" : "menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-8168"}><a href="/" onClick={(e) => {e.preventDefault(); handleSubMenu(2)}}>International Alliance</a>
                <ul className="sub-menu" style={{display: subMenus[2] ? "block" : "none"}}>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8208"><a href="https://www.diabetesindia.org.in/idf/">IDF</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8207"><a href="https://www.diabetesindia.org.in/ada/">ADA</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8206"><a href="https://www.diabetesindia.org.in/desg/">DESG</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8205"><a href="https://www.diabetesindia.org.in/easd/">EASD</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8204"><a href="https://www.diabetesindia.org.in/pcde/">PCDE</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8203"><a href="https://www.diabetesindia.org.in/codhy/">CODHY</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8202"><a href="https://www.diabetesindia.org.in/ispad/">ISPAD</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8201"><a href="https://www.diabetesindia.org.in/dasg/">DASG</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8200"><a href="https://www.diabetesindia.org.in/attd/">ATTD</a></li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8199"><a href="https://www.diabetesindia.org.in/ihsg/">IHSG</a></li>
                </ul>
                <span className="radiantthemes-open-submenu"></span></li>
              <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8254"><a target="_blank" rel="noopener" href="https://www.diabetesindia2023.com/">Conference</a></li>
              <li className="login-logout menu-item menu-item-type-post_type menu-item-object-page menu-item-9713"><a href="https://www.diabetesindia.org.in/login/">Login</a></li> */}
            </ul></div>            </nav>
        </div>
      </div>
      
      </div> : null }
      {/* Popups */}
      {editProfilePopup ? 
      <UpdateProfile
        visible={editProfilePopup}
        onClose={(val) => handlePopupClose("profile", val)}  
      /> : null }
    </>
  )
}

export default Header;