import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
export const fileUpload = async (attachment, type) => {

    let s3_url = "";
    let target = {};
    if(type === "image"){
        s3_url = 'https://public-awareness-images.s3.ap-south-1.amazonaws.com/' + attachment.name;
        target = { Bucket: 'public-awareness-images', Key: attachment.name, Body: attachment };
    }else{
        s3_url = 'https://public-awareness-video.s3.ap-south-1.amazonaws.com/' + attachment.name;
        target = { Bucket: 'public-awareness-video', Key: attachment.name, Body: attachment };
    }
    

    const creds = {
        accessKeyId: 'AKIATT4SF2SQDEUQB7J7',
        secretAccessKey: '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',
    };
    try {
        const parallelUploads3 = new Upload({
            client: new S3Client({ region: 'ap-south-1', credentials: creds }),
            leavePartsOnError: false, // optional manually handle dropped parts
            params: target,
        });

        parallelUploads3.on('httpUploadProgress', (progress) => {
          console.log(progress,'vandana');
        });

        await parallelUploads3.done();

        return s3_url;

    } catch (e) {
        // console.log(e);
        return "image not found."
    }

    // 

}

