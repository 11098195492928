import { Modal } from "react-bootstrap";
import "./diabetesgroup.css";
import { useNavigate } from "react-router-dom";
const DiabetesPopup = (props) => {
    const navigate = useNavigate();
    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Modal
            onHide={handleClose}
            // size={"sm"}
            show={props.visible}
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Body>
                <div className="success-div">
                    <i className="far fa-check-circle icon"></i>
                    <p className="title">Thank you very much for your response.</p>
                    <p className="msg">Your username and password is mailed at your email id which you have entered during registration.</p>
                    <div style={{maxWidth:"220px"}}>
                        <button type="button" className="button" onClick={() => navigate("/dashboard")} style={{ width: "100%", height: "57px" }}>
                            <span className="ubtn-hover" style={{ background: "black" }}></span>
                            <a className="ubtn-data ubtn-text " href="/" onClick={(e) => e.preventDefault()}>Continue to Dashboard</a>
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default DiabetesPopup;
