import { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "./diabetesgroup.css";
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import Select from 'react-select'
const ChangeGroup = (props) => {

    const [removedGroups, setRemovedGroups] = useState([]);
    const [interestGroups, setInterestGroups] = useState([]);
    const [availableGroups, setAvailableGroups] = useState([])
    useEffect(() => {
        getUserGroupData();
    }, []);
    
    const getUserGroupData = () => {
        axios
            .get(site_ip + '/getUserGroups')
            .then((res) => {
                let selected_ids = [];
                props.existingGroups?.map((item)=>{
                    selected_ids.push(item?.ug_id)
                })

                let filtered_list = res.data.Content?.filter((item) =>{
                    if(!selected_ids.includes(item?.ug_id)){
                        return item
                    }
                })
                setAvailableGroups(filtered_list);
            })
            .catch((err) => console.log(err));
    };

    const [error, setError] = useState("");
    const handleClose = () => {
        props.handleClose();
    };
    
    const handleUserGroup = (user_id) => {
        let req = [];
        interestGroups?.map((item)=>{
            let ele = {
                ug_id: item?.ug_id,
                whatsapp_group_join_ind: "N",
                user_id: user_id
            };
            req.push(ele)
        })

        axios
            .post(site_ip + '/auth/addUserRegistrationGroups', req)
            .then((res) => {
                alert("Successfully registered! Please login to continue.")
                setError('');
                props.handleClose();
            })
            .catch((err) => console.log(err));
    }

    return (
        <Modal
            onHide={handleClose}
            show={props.textPopup}
            size={"md"}
            // dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Request for Change of Group
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <div style={{ height: "auto" }}>
                        {error && (
                            <div className="form-group">
                                <div
                                    className="alert alert-danger alert-dismissible"
                                    role="alert"
                                >
                                    {error}
                                </div>
                            </div>
                        )}
                    </div>
                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>Diabetes Interest Group you want to leave?<span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Select
                                getOptionLabel={(option) => option.ug_name}
                                getOptionValue={(option) => option.ug_id}
                                labelKey={"ug_name"}
                                valueKey={"ug_id"}
                                optionLabel={"Zone"}
                                value={removedGroups}
                                options={props?.existingGroups}
                                isOptionDisabled={() => removedGroups.length >= 1}
                                onChange={(selected) => {
                                    setRemovedGroups(selected)
                                }}
                                isMulti
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label style={{ fontSize: "11px" }}>Diabetes Interest Group you want to join<span style={{ color: "#ff0000" }}>*</span></Form.Label>
                            <Select
                                getOptionLabel={(option) => option.ug_name}
                                getOptionValue={(option) => option.ug_id}
                                labelKey={"ug_name"}
                                valueKey={"ug_id"}
                                optionLabel={"Zone"}
                                value={interestGroups}
                                options={availableGroups}
                                isOptionDisabled={() => interestGroups.length >= 1}
                                onChange={(selected) => {
                                    setInterestGroups(selected)
                                }}
                                isMulti
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer
                className="card-footer"
            >
                <Button
                    className="submit"
                    type="button"
                    style={{ width: "120px" }}
                    onClick={() => handleUserGroup()}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default ChangeGroup;
