import { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "./diabetesgroup.css";
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import { useNavigate } from "react-router-dom";
const DiabetesPopup = (props) => {
    const navigate = useNavigate();
    const [forgotPassword, setForgotPassword] = useState(false);
    const [regEmail, setRegEmail] = useState("")
    const initialState = {
        user_email: "",
        password: "",
    };
    const [siteUserData, setSiteUserData] = useState(initialState);
    const handleChange = (e) => {
        setError('');
        const { name, value } = e.target;
        setSiteUserData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    }

    const [error, setError] = useState("");
    const handleClose = () => {
        props.handleClose();
    };

    const handleSubmit = async () => {
        const userData = {
            user_email: siteUserData?.user_email,
            password: siteUserData?.password,
        };
        axios
            .post(site_ip + '/auth/signin', userData)
            .then((response) => {
                if (response?.data.Status === 200) {
                    localStorage.setItem('userToken', response?.data.Token);
                    localStorage.setItem('user_id', response?.data.Content.user_id);
                    localStorage.setItem('user', JSON.stringify(response?.data.Content));
                    localStorage.setItem('user_group', JSON.stringify(response?.data.UserGroups));
                    navigate('/dashboard');
                } else {
                    setError("Invalid Credentials")
                }
            })
            .catch((err) => setError("Invalid Credentials"));

    };

    const submitForgotPassword = async () => {
        const userData = {
            user_email: regEmail
        };
        axios
            .post(site_ip + '/auth/forgotPassSend', userData)
            .then((response) => {
                alert("New password sent to your registered email.")
                setForgotPassword(false)
            })
            .catch((err) => setError("Invalid Credentials"));

    };

    return (
        <Modal
            onHide={handleClose}
            // size={"sm"}
            show={props.textPopup}
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {forgotPassword ? "Forgot Password" : "Login"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!forgotPassword ?
                    <Row>
                        <div style={{ height: "auto" }}>
                            {error && (
                                <div className="form-group">
                                    <div
                                        className="alert alert-danger alert-dismissible"
                                        role="alert"
                                    >
                                        {error}
                                    </div>
                                </div>
                            )}
                        </div>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ fontSize: "11px" }}>E-mail / Username<span style={{ color: "#ff0000" }}>*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="user_email"
                                    value={siteUserData?.user_email}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ fontSize: "11px" }}>Password <span style={{ color: "#ff0000" }}>*</span></Form.Label>
                                <Form.Control
                                    type="password"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="password"
                                    value={siteUserData?.password}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <a href="/" onClick={(e) => { e.preventDefault(); setForgotPassword(true) }}>Forgot Password?</a>
                        </Col>
                    </Row> :
                    <Row>
                        <div style={{ height: "auto" }}>
                            {error && (
                                <div className="form-group">
                                    <div
                                        className="alert alert-danger alert-dismissible"
                                        role="alert"
                                    >
                                        {error}
                                    </div>
                                </div>
                            )}
                        </div>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ fontSize: "11px" }}>Your registered E-mail / Username<span style={{ color: "#ff0000" }}>*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="user_email"
                                    value={regEmail}
                                    onChange={(e) => setRegEmail(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <a href="/" onClick={(e) => { e.preventDefault(); setForgotPassword(false) }}>Continue to login</a>
                        </Col>
                    </Row>}
            </Modal.Body>
            <Modal.Footer
                className="card-footer"
            >
                <Button
                    className="submit"
                    type="button"
                    style={{ width: "120px" }}
                    onClick={() => {forgotPassword ? submitForgotPassword() : handleSubmit()}}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default DiabetesPopup;
