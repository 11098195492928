import "./Footer.css";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import cppl from "../assets/img/logo.png";

const Footer = () => {
  return (
    <footer className="wraper_footer style-one">
      <div className="wraper_footer_main">
        <div className="container">
          <div className="row footer_main">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12"><div className="footer_main_item matchHeight" style={{ height: "263px" }}>
              <section id="media_image-2" className="widget widget_media_image">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img width="120" height="120" src="https://www.diabetesindia.org.in/wp-content/uploads/2022/05/diabetesindia-logo.png" className="image wp-image-8079  attachment-full size-full" alt="" decoding="async" loading="lazy" style={{ maxWidth: "100%", height: "auto" }} srcset="https://www.diabetesindia.org.in/wp-content/uploads/2022/05/diabetesindia-logo.png 120w, https://www.diabetesindia.org.in/wp-content/uploads/2022/05/diabetesindia-logo-100x100.png 100w" sizes="(max-width: 120px) 100vw, 120px" data-no-retina="" />
                </div>
              </section>
              <section id="text-1" className="widget widget_text">

                <div className="textwidget"><p>Welcome to Diabetes India. We are a global community of more than 10,000 diabetes care professionals. we intend to disseminate latest.</p>
                </div>
              </section></div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12"><div className="footer_main_item matchHeight" style={{ height: "263px" }}><section id="nav_menu-28" className="widget widget_nav_menu"><h5 className="widget-title">Useful Links</h5><div className="menu-footer-userful-links-container"><ul id="menu-footer-userful-links" className="menu"><li id="menu-item-8333" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8333"><a href="https://www.diabetesindia.org.in/about-diabetes-india/">About Diabetes India</a></li>
              <li id="menu-item-9403" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9403"><a href="https://www.diabetesindia.org.in/terms-conditions/">Terms &amp; Conditions</a></li>
              <li id="menu-item-9402" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9402"><a href="https://www.diabetesindia.org.in/privacy-policy/">Privacy Policy</a></li>
              <li id="menu-item-9048" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9048"><a href="https://www.diabetesindia.org.in/contact-us/">Contact Us</a></li>
              <li id="menu-item-9049" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9049"><a href="https://diabetesindia2022.com/">Conference</a></li>
            </ul></div></section></div>
            </div><div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"><div className="footer_main_item matchHeight" style={{ height: "263px" }}><section id="text-2" className="widget widget_text"><h5 className="widget-title">Contact Us</h5>			<div className="textwidget"><p><strong>Office Address</strong></p>
              <p>1 &amp; 2, Gandhi Park, Near Nehrunagar Road, Opp, BRTS, L Colony, Ambawadi, Ahmedabad, Gujarat 380015 (India)</p>
              <p><strong>Phone:</strong> +91 79 26304104 / 8104</p>
              <p><strong>Email:</strong> <a href="mailto:Diabetesindia2021@gmail.com">Diabetesindia2021@gmail.com</a></p>
            </div>
            </section>
            </div>
            </div>
            {/* <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"><div className="footer_main_item matchHeight" style={{ height: "263px" }}>

              <div id="sbc">
                <form method="get" id="sbc-search" className="form-group" action="#">

                  <div className="col-md-12"> <input type="email" aria-describedby="email-error" name="subscribe_email" placeholder="Email Address" id="fl-subscribe-form-email" className="fl-subscribe-form-email" required="" /></div>
                  <div className="col-md-12"><input type="button" className="btn btn-primary" name="sbc-submit" id="sbc-submit" value="Subscribe" /></div>

                </form>
                <div id="blogmsg"></div>
              </div>

              <section id="text-4" className="widget widget_text"><h5 className="widget-title">Newsletter</h5>			<div className="textwidget">
              </div>
              </section>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="wraper_footer_copyright">
        <div className="container">
          <div className="row footer_copyright" style={{ display: "none" }}>


            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="footer_copyright_item text-left">
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="footer_copyright_item text-center">
                <p>This website is managed and maintained by:</p>
              </div>
            </div>
          </div>
          <div className="row footer_copyrighttxt">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
              &nbsp;
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="footer_copyright_item text-center">
                <p>This website is managed and maintained by:</p>
              </div>
              <p className="messD">
                <img src="https://www.diabetesindia.org.in/wp-content/uploads/2022/06/Knowledge-Bridge-Private-Limited-logo.png" align="left" style={{ width: "115px", float: "left" }} data-no-retina="" /> Knowledge Bridge Private Limited, Mumbai, India.
              </p>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
              &nbsp;
            </div>


          </div>


        </div>
      </div>
    </footer>
  );
};

export default Footer;
