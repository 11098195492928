import React, { useState, useEffect } from 'react';
import "./diabetesgroup.css";
import { Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../globalSetting';
import JoinNewGroup from "./JoinNewGroup";
import ChangeGroup from "./ChangeGroup";

const Dashboard = () => {
    const data = localStorage.getItem('user_group');
    const [userGroup, setUserGroup] = useState([]);
    const user = localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : {};
    const [joinGroupPopup, setJoinGroupPopup] = useState(false)
    const [changeGroupVisible, setChangeGroupVisible] = useState(false)

    useEffect(()=>{
        getUserGroups();
    }, [])

    const getUserGroups = () => {
        
        axios
            .get(site_ip + '/auth/getRegisteredUserGroups/'+localStorage.getItem("user_id"))
            .then((res) => {
                setUserGroup(res.data.Content)
            })
            .catch((err) => console.log(err));

    }

    const handleClose = (val) =>{
        if(val === 1){
            getUserGroups(); 
            
            alert("New group added successfully")
        }
        setJoinGroupPopup(false)
    }

    return (
        <Container>
            <div className='row mt-3 dashboard' style={{ margin: 0 }}>
                <div style={{ backgroundColor: "#f5f5f5", padding: "15px" }}>
                    <Row>
                        <Col sm={8} style={{ height: "100%" }}>
                            <Row style={{ height: "100%" }}>
                                <Col sm={12} style={{ height: "100%" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                        <img src={"/assets/userpic.png"} style={{ height: "120px", width: "120px", backgroundColor: "#ccc", borderRadius: "60px" }} />
                                        <div style={{ marginLeft: "15px" }}>
                                            <h4>{user?.first_name + " " + user?.last_name}</h4>
                                            <h6>{user?.user_email}</h6>
                                            <h6>{user?.user_mobile}</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={1}><div style={{height:"15px"}}></div></Col>
                        <Col sm={3}>
                            <Row>
                                {userGroup?.length === 1 ?
                                    <Col xs={12}>
                                        <button type="button" className="button" style={{ width: "100%", height: "57px", marginBottom: "15px" }} onClick={() => setJoinGroupPopup(true)}>
                                            <span className="ubtn-hover" style={{ background: "black" }}></span>
                                            <span className="ubtn-data ubtn-text ">Join Second Group</span>
                                        </button>
                                        <JoinNewGroup
                                            existingGroups={userGroup}
                                            textPopup={joinGroupPopup}
                                            handleClose={(val) => {handleClose(val); }}
                                        />
                                    </Col> 
                                : null}
                                <Col xs={12}>
                                    <button type="button" className="button" style={{ width: "100%", height: "57px", marginBottom: "15px" }} onClick={() => setChangeGroupVisible(true)}>
                                        <span className="ubtn-hover" style={{ background: "black" }}></span>
                                        <span className="ubtn-data ubtn-text ">Request for change Group</span>
                                    </button>
                                    <ChangeGroup
                                        existingGroups={userGroup}
                                        textPopup={changeGroupVisible}
                                        handleClose={() => setChangeGroupVisible(false)}
                                    />
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </div>
                <div className='main mt-3' id="a">
                    <h2>User Groups</h2>
                    {userGroup.map((option, index) => {
                        return (
                            <SubscribedGroups item={option} index={index}/>
                        )
                    }
                    )}
                </div>
            </div>
        </Container>
    )
}

export default Dashboard


const SubscribedGroups = ({ item, index }) => {

    const groupJoined = async () => {
        
        const userData = {
            user_id: localStorage.getItem("user_id"),
            whatsapp_group_join_ind: "Y",
            ug_id:item?.ug_id
        };
        axios
            .post(site_ip + '/auth/joinWhatsappGroup', userData)
            .then((response) => {
                window.open(item?.wa_group_join_link, '_blank');
                // alert("Successfully joined.")
            })
            .catch((err) => alert("Something went wrong"));

    };


    return (
        <div className="group-row">
            <div className="row">
                <div className="col-md-8">
                    <div style={{ display: "flex", alignItems: 'center', height: "100%" }}>
                        <p><b>{index + 1}. </b>{item?.ug_name}</p>
                    </div>
                </div>
                <div className="col-md-4">
                    {item?.whatsapp_group_join_ind === "N" ? 
                    <button type="button" className="button" style={{ width: "100%", height: "57px", marginBottom: "15px" }} onClick={() => groupJoined()}>
                        <span className="ubtn-hover" style={{ background: "black" }}></span>
                        <span className="ubtn-data ubtn-text ">Click here to join WhatsApp Group</span>
                    </button> : 
                    <div>
                        <button disabled type="button" className="secondary-button" style={{ width: "100%", height: "57px", marginBottom: "15px" }}>
                            <span className="ubtn-hover" style={{ background: "black" }}></span>
                            <span className="ubtn-data ubtn-text ">Group joined</span>
                        </button>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <a href='/' onClick={(e) => {e.preventDefault(); groupJoined()}}>Click here to open again</a>
                        </div>
                    </div> }
                </div>
            </div>
        </div>
    )
}
