import React, { useState, useEffect } from 'react';
import { site_ip } from '../../globalSetting';
import { Container, Col, Row, Form, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import AddDocters from './AddDocters';
const PublicAwareness = () => {
    const [language, setLanguage] = useState([]);
    const [languageSelect, setLanguageSelect] = useState('');
    const [name, setName] = useState('');
    const [languageName, setLanguageName] = useState('');
    const [contentId, setContentId] = useState('');
    const [contentImage, setContentImage] = useState('');
    const [images, setImages] = useState([]);
    const [displayPopUp, setDisplayPopUp] = useState(false);
    useEffect(() => {
        getLanguageList();
        handleLanguage();
        // eslint-disable-next-line
    }, []);
    const getLanguageList = () => {
        // Send a Get request
        axios
            .get(site_ip + "/getLanguages")
            .then((response) => {
                setLanguage(response.data.Content);
            })
            .catch((error) => {
                console.log(error.response.data.message);
            });
    };
    
    const handleLanguage = () => {
        const id = languageSelect === '' ? '1' : languageSelect;
        axios
            .get(site_ip + "/getAwarenessVideosByLanguage/" + id)
            .then((response) => {
                setImages(response.data.Content);
                setLanguageName(name);
            })
            .catch((error) => {
                console.log(error.response.data.message);
            });

    }
    const handlePopUp = (av_id, video_image) => {
        setContentId(av_id);
        setContentImage(video_image);
        setDisplayPopUp(true);
    }
    return (
        <Container style={{height:"auto"}}>
            <Row style={{ marginTop: '50px', paddingTop:"30px", paddingBottom:"30px", marginBottom: '50px', background: '#f5f5f5', boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                <Col md={2}></Col>
                <Col md={2}>
                    <div style={{display:"flex", alignItems:'center', height:"100%"}}>
                        <p style={{marginBottom:"0px", fontWeight:"bold"}}>Choose Your Language:</p>
                    </div>
                </Col>
                <Col md={4}>
                    <Form.Control
                        as="select"
                        custom
                        onChange={(e) => { setName(e.target.options[e.target.selectedIndex].text); setLanguageSelect(e.target.value) }}
                    >
                        {language.map((data, index) => {
                            return (
                                <option value={data.language_id}>{data.language_name}</option>
                            )
                        })}
                    </Form.Control>
                </Col>
                <Col md={2}>
                    <Button variant="success" type="button" onClick={() => handleLanguage()} style={{ width: '100px'}}>
                        Submit
                    </Button>
                </Col>
                <Col md={2}></Col>
            </Row>
            <Row style={{background: '#f5f5f5', boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", paddingTop:"15px", paddingBottom:"15px", marginBottom:"30px"}}>
                <Col>
                    <h5 style={{marginBottom:"0px"}}>{languageName === '' ? 'English' : languageName}</h5>     
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {/*  */}
                    <Row md={4} className="">
                        {images.map((data, index) => {
                            return (
                                <Col style={{ paddingLeft: '30px', marginBottom: '40px' }}>
                                    <Card >
                                        <Card.Img variant="top" src={data.video_image} crossOrigin='anonymous' />
                                        <Card.Body>
                                            <Card.Text>
                                                {data.video_title}
                                            </Card.Text>
                                            <Button variant="danger" style={{ width: '100px' }} onClick={() => handlePopUp(data.av_id, data.video_image)}>Download</Button>
                                        </Card.Body>
                                    </Card>

                                </Col>
                            )
                        })}

                    </Row>
                </Col>
            </Row>
            <AddDocters
                textPopup={displayPopUp}
                languageId={languageSelect === '' ? 1 : languageSelect}
                contentId={contentId}
                contentImage={contentImage}
                handleClose={() => {
                    setDisplayPopUp(false);
                }}
            />
        </Container>
    )
}
export default PublicAwareness;