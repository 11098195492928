import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
} from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../../../globalSetting';
import DataTable from 'react-data-table-component';
import CustomTableStyle from '../customTableStyle';
import { useNavigate } from 'react-router-dom';
import "../admin.css"
// import BackButton from "../common/BackButton";
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
// import { CSVLink } from 'react-csv';

export default function LoginCount(props) {

  const navigate = useNavigate();
  const blankSearch = {
    content_title: '',
    language_id: '',
    subject_id: '',
  }
  const [search, setSearch] = useState(blankSearch);
  const [language, setLanguage] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [publicImage, setPublicImage] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    getLanguageList();
    getSubjectList();
    fetchPublicImage();
    // eslint-disable-next-line
  }, []);
  const getLanguageList = () => {
    // Send a Get request
    axios
      .get(site_ip + "/getLanguages")
      .then((response) => {
        setLanguage(response.data.Content);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  const getSubjectList = () => {
    // Send a Get request
    axios
      .get(site_ip + "/getSubjects")
      .then((response) => {
        setSubjects(response.data.content);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const fetchPublicImage = (req) => {
    axios
      .post(site_ip + '/searchDoctorContents', req)
      .then(function (response) {
        setPublicImage(response.data.Content);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  let tableHeader = [
    {
      name: '#',
      selector: (row, index) => (index + 1),
      width: "100px"
    },
    {
      name: 'Content Title',
      selector: (row) => row?.content_title,
    },
    {
      name: 'Language',
      selector: (row) => row?.language_name,
    },
    {
      name: 'Subject',
      selector: (row) => row?.subject_name,
    },
    {
      name: 'Image',
      selector: (row) => row?.loginyear,
      cell: (row) => (
        <>
          <img src={row?.content_image} style={{ width: "60px", height: "80px" }} />
        </>
      )
    },
    {
      name: 'Action',
      width: "100px",
      cell: (row) => (
        <>
          <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            <button style={{ padding: "5px" }} onClick={() => navigate("/admin/edit-image/" + row.content_id)} className="primary-plus-button">
              <i className="fas fa-pencil-alt" style={{ color: "#ffffff" }}></i>
            </button>
          </div>
          {/* <div style={{ paddingTop: "5px", paddingBottom: "5px", marginLeft: "5px" }}>
            <button style={{ padding: "5px" }} onClickCapture={() => setConfirmation(row.content_id)} className="secondary-plus-button">
              <i className="far fa-trash-alt" style={{ color: "#ffffff" }}></i>
            </button>
          </div> */}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
  ]

  return (
    <>
      <Row style={{ margin: 0 }}>
        <Col xl={12} className="mt-3">
          <div className="bg-white">
            <div className="admin-filter-box">
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Language</Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="language_id"
                      value={search.language_id}
                      onChange={handleChange}
                    >
                      <option>Select Language</option>
                      {language?.map((option) => (
                        <option value={option.language_id}>
                          {option.language_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      as="select"
                      className="mb-3"
                      name="subject_id"
                      value={search.subject_id}
                      onChange={handleChange}
                    >
                      <option>Select Subject</option>
                      {subjects?.map((option) => (
                        <option value={option.subject_id}>
                          {option.subject_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Content Title</Form.Label>
                    <Form.Control
                      type="text"
                      className="mb-3"
                      name="content_title"
                      value={search.content_title}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="btn-box  border-top mt-3 pt-3">
                <button
                  className="success"
                  onClick={() => fetchPublicImage(search)}
                >
                  Apply
                </button>
                <button className="close" onClick={() => { fetchPublicImage(blankSearch); setSearch(blankSearch) }}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ margin: 0 }}>
        <Col>
          <DataTable columns={tableHeader} data={publicImage} pagination customStyles={CustomTableStyle} />
        </Col>
      </Row>
    </>
  );
}
