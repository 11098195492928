import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import "./diabetesgroup.css";
import DiabetesPopup from "./Diabetespopup"
import LoginPopup from "./LoginPopup";
import SuccessPopup from "./SuccessPopup";
const DiabetesGroup = () => {
    const [DiabetesGroupPopup, setDiabetesGroupPopup] = useState(false);
    const [loginVisible, setLoginVisible] = useState(false);
    const [successPopupVisible, setSuccessPopupVisible] = useState(false)
    const [zoneName, setZoneName] = useState([]);
    const handleDiabetes = () => {
        setDiabetesGroupPopup(true);
    };
    return (
        <div className='row mt-3' style={{ margin: 0 }}>
            <div className='main col-md-9 m-auto' id="a">
			     <h2>Engage with Specialized Diabetes Interest Groups (DIGs)</h2>
				 <h3>Professional Engagement and Collaboration</h3>
                <p>The Research Trust of Diabetes India is pleased to present medical professionals with the opportunity to engage in our Diabetes Interest Groups (DIGs). These groups are tailored to facilitate meaningful exchanges, foster professional networks, and promote collaborative research endeavors in distinct areas of diabetes care and management.</p>
 <p>
<strong>Advantages of DIG</strong>
<ul style={{ listStyle: "inherit", listStyleType: "disc" }}>
 	<li>Knowledge Dissemination: Share and gain insights on recent advancements and practical approaches in diabetes care.</li>
 	<li>Professional Connections: Forge valuable connections with peers and experienced practitioners in your field of interest.</li>
 	<li>Joint Research Initiatives: Collaborate on research projects to explore novel solutions and methodologies in diabetes management.</li>
 	<li>Mentorship and Leadership: Grow professionally with the guidance of accomplished leaders steering each DIG.</li>
</ul></p>
 <p>
<strong>DIG Operational Framework</strong>
<ul style={{ listStyle: "inherit", listStyleType: "disc" }}>
 	<li>Each DIG operates under a structured framework to ensure goal-oriented discussions and initiatives. Chairs and Committee members undertake a two-year term to guide the activities of the DIG.</li>
</ul></p>
<p><strong>Explore Our Diverse DIGs</strong> <br/>
Dive into specialized groups aligned with your professional focus:</p>
                <Row>
                    <Col md={6}>
                        <div style={{ paddingLeft: "30px" }}>
                            <ol style={{ listStyle: "inherit", listStyleType: "decimal" }}>
                                <li>Diabetes and Foot</li>
								<li>Diabetes and Hypoglycaemia</li>
								<li>Diabetes and Liver</li>
								<li>Diabetes and Mental Health</li>
								<li>Diabetes and Obesity</li>
								<li>Diabetes and Oral health</li>
								<li>Diabetes and Sexual Dysfunction</li>
								<li>Diabetes and Women</li>
								<li>Diabetes in Elderly</li>
								<li>Diabetes Kidney Disease</li>
								<li>Diabetes Technology</li>
								<li>Exercise &amp; Lifestyle</li>
								<li>Heart in Diabetes</li>
								<li>Pediatric Diabetes</li>
								<li>Primary Care</li>
								<li>Public Health, Diabetes Education &amp; Advocacy</li>
                            </ol>
                        </div>
                    </Col>
                    <Col md={6}>
					
						
                        <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
						<div>
						<img className="img-thumbnail" src="https://www.diabetesindia.org.in/wp-content/uploads/2023/10/group-instr-1.jpeg" alt="dibetesindia"/>
							<button type="button" class="button"  onClick={() => window.open("https://www.diabetesindia.org.in/dig-leadership/", '_blank')}>
								<span className="ubtn-hover" style={{ background: "black" }}></span>
								<span className="ubtn-data ubtn-text ">DIG Leadership Details</span>								
							</button>
						</div>
						 </div>
                    </Col>
                </Row>
                <p><strong>Join Our Exemplary Community</strong> <br/>
				Extend your professional horizons by becoming a part of this exclusive community.</p>
            </div>
            <div className="row mb-5">
                <div className="col-sm-3"></div>
                <div className="col-sm-3">
                    <button type="button" className="button" style={{ width: "100%", height: "57px", marginBottom: "15px" }} onClick={() => handleDiabetes()}>
                        <span className="ubtn-hover" style={{ background: "black" }}></span>
                        <span className="ubtn-data ubtn-text ">Click here to Join 2 Groups</span>
                    </button>
                    <DiabetesPopup
                        textPopup={DiabetesGroupPopup}
                        handleClose={(val) => {
                            setDiabetesGroupPopup(false);
                            if (val === 1) {
                                setSuccessPopupVisible(true)
                            }
                        }}
                    />
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-3">
                    <button type="button" className="button" onClick={() => setLoginVisible(true)} style={{ width: "100%", height: "57px" }}>
                        <span className="ubtn-hover" style={{ background: "black" }}></span>
                        <a className="ubtn-data ubtn-text " href="/" onClick={(e) => e.preventDefault()}>Already a Member Click here to Login</a>
                    </button>
                    <LoginPopup
                        textPopup={loginVisible}
                        handleClose={() => setLoginVisible(false)}
                    />
                </div>
			
            </div>
            <SuccessPopup
                visible={successPopupVisible}
                handleClose={() => setSuccessPopupVisible(false)}
            />
        </div >

    )
}

export default DiabetesGroup