// import logo from './logo.svg';
import './App.css';
import DiabetesGroup from './components/DiabetesGroup/DiabetesGroup.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Login from './components/login';
import Dashboard from './components/DiabetesGroup/Dashboard';
import PublicAwareness from './components/PublicAwareness';
import PublicVideoAwarness from './components/PublicVideoAwareness'
import Admin from "./admin"
function App() {
  const PublicLayout = (component) => {
    return (
      <>
        <Header />
        {component}
        <Footer />
      </>

    );
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={PublicLayout(<DiabetesGroup />)} />
        <Route path="/login" element={PublicLayout(<Login />)} />
        <Route path="/dashboard" element={PublicLayout(<Dashboard />)} />
        <Route path="/public-awareness" element={PublicLayout(<PublicAwareness />)} />
        <Route path="/public-video-awareness" element={PublicLayout(<PublicVideoAwarness />)} />
        <Route
          path="/admin/*"
          element={<Admin />}
        />
      </Routes>
    </Router>
  )
};

export default App;
