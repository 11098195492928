import React from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import Login from "./content/login";
// import Signup from './content/Signup';
import Logout from "./content/Logout";
import TopBar from "./content/TopBar";
import LeftSideBar from "./content/LeftSideBar";


import Dashboard from "./content/Dashboard";

import PublicImageList from "./content/PublicImages/View";
import AddPublicImage from "./content/PublicImages/Add";
import EditPublicImage from "./content/PublicImages/Edit";

import PublicVideoList from "./content/PublicVideos/View";
import AddPublicVideo from "./content/PublicVideos/Add";
import EditPublicVideo from "./content/PublicVideos/Edit";


function App() {
  const BlankLayout = ({ children }) => <>{children}</>;
  const LayoutOfAfterLogin = (children) => (
    <>
      <TopBar />
      <LeftSideBar />
      <div className="right-block">
        <div className="content-wrapper">{children}</div>
      </div>
    </>
  );

  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/login" layout={BlankLayout} element={<Login />} />
      <Route exact path="/logout" layout={BlankLayout} element={<Logout />} />

      <Route
        path="/dashboard"
        exact
        element={LayoutOfAfterLogin(<Dashboard />)}
      />

      <Route path="/images" exact element={LayoutOfAfterLogin(<PublicImageList />)} />
      <Route
        path="/add-image"
        exact
        element={LayoutOfAfterLogin(<AddPublicImage />)}
      />

      <Route
        path="/edit-image/:image_id"
        exact
        element={LayoutOfAfterLogin(<EditPublicImage />)}
      />
      <Route path="/videos" exact element={LayoutOfAfterLogin(<PublicVideoList />)} />
      <Route
        path="/add-video"
        exact
        element={LayoutOfAfterLogin(<AddPublicVideo />)}
      />

      <Route
        path="/edit-video/:video_id"
        exact
        element={LayoutOfAfterLogin(<EditPublicVideo />)}
      />
     

    </Routes>
  );
}

export default App;
